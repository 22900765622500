export enum OfferType {
  ONLINE = 1,
  OFFLINE = 2,
}

export type ActiveOffersFilter = {
  businessTypes: Array<string>;
  offerTypeId?: OfferType;
};

export enum VoucherDisplayFormat {
  DEFAULT = 1,
  QR_CODE = 2,
}

export type ClimatePartner = {
  address: string;
  business_type: {
    id: number;
    slug: string;
    name: string;
  };
  city: string;
  country: string;
  distance?: number;
  email: string;
  id: number;
  icon: string;
  latitude: number;
  longitude: number;
  logo: string;
  logo_file: {
    url: string;
  };
  name: string;
  phone_number: string;
  postal_code: string;
};

export type Voucher = {
  id: number;
  wishlisted: boolean;
  title: string;
  recoins: number;
  cost: number;
  email: string;
  phone_number: string;
  offers_count: number;
  limit_timeframe: number;
  limit_per_user: number;
  expires_at: string;
  remaining: number;
  image: string;
  type: OfferType;
  offer_type_id: OfferType;
  offer_display_format_id: VoucherDisplayFormat;
  userRecoins: number;
  image_file: {
    url: string;
  };
  climate_partner?: ClimatePartner;
  climate_partners?: ClimatePartner[];
};

export type PurchasedVoucher = {
  code?: string;
  display_format?: number;
  email?: any;
  expires_at?: Date;
  id: number;
  purchased_at?: Date;
  redeemed?: boolean;
  offer: Voucher;
  voucher_id?: number;
  qr_code?: {
    image_file: {
      url: string;
    };
  };
};

export enum DealTypes {
  LOTTERY = 'lottery',
  VOUCHERS = 'vouchers',
}

export type OffersBusinessType = {
  id: number;
  slug: string;
  name: string;
};
