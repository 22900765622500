import {ClimatePartner} from 'types/Offers';

interface AddSuffixIfExists {
  value?: string;
  suffix: string;
}

interface GetScaledFontSize {
  value: string;
  fontSize: number;
  maxChar: number;
}

export const addSuffixIfExists = ({value, suffix}: AddSuffixIfExists) => {
  return value ? `${value}${suffix}` : '';
};

export const getScaledFontSize = ({
  value,
  fontSize,
  maxChar,
}: GetScaledFontSize): number => {
  if (value.length <= maxChar) {
    return fontSize;
  }

  return (
    (1 - Math.log(((value.length - maxChar) / maxChar) * 10) / 10) *
    fontSize *
    0.85
  );
};

type Trim = {
  text: string;
  length: number;
};
export const trim = ({text, length}: Trim) => {
  if (text?.length > length) {
    const splitText = text.substring(0, length).split(' ');
    return `${splitText.slice(0, splitText.length - 1).join(' ')} ...`;
  }

  return text;
};

export const formatClimatePartnerAddress = ({
  climatePartner,
}: {
  climatePartner: undefined | ClimatePartner;
}) =>
  climatePartner
    ? [
        climatePartner.address,
        // climatePartner.postal_code,
        // climatePartner.city,
      ]
        .filter((value) => value)
        .join(', ')
    : '';

export const toBoolean = (value: any) => !!value && value !== 'false';

interface GlobalReplaceParams {
  text: string;
  search: string;
  replace: string;
}

export const globalReplace = ({text, search, replace}: GlobalReplaceParams) => {
  const escapedSearch = search.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(escapedSearch, 'g');
  return text.replace(regex, replace);
};

export const endsWithNumbers = ({text}: {text: string}) => {
  const regex = /\d+$/;
  return regex.test(text);
};

export const wrapLinksInAnchorTags = (text: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');

  // Regular expression to match all URLs
  const urlRegex = /https?:\/\/[^\s<]+/g;

  const findAndWrapLinks = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent || '';
      const newContent = textContent.replace(urlRegex, (url) => {
        // Check if the URL is already wrapped in an anchor tag
        if (node.parentElement && node.parentElement.tagName.toLowerCase() === 'a') {
          return url; // It's already wrapped in an <a> tag
        }
        return `<a href="${url}" target="_blank">${url}</a>`;
      });

      // Safely check if the node has replaceWith
      if (newContent !== textContent && 'replaceWith' in node) {
        const newFragment = document.createRange().createContextualFragment(newContent);
        (node as ChildNode).replaceWith(newFragment); // Use type assertion to satisfy TypeScript
      }
    } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName.toLowerCase() !== 'a') {
      // Recurse through the child nodes if it's not an <a> element
      Array.from(node.childNodes).forEach(findAndWrapLinks);
    }
  };

  // Find and wrap links within the document body
  Array.from(doc.body.childNodes).forEach(findAndWrapLinks);

  // Return the updated HTML as a string
  return doc.body.innerHTML;
};

