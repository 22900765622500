import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import routes from 'config/routes/rewards';

import {useOrganization} from 'hooks/use-organization';
import {useUserProfile} from 'hooks/use-user-profile';
import {SettingsContext} from 'contexts/SettingsContext';
import {LocationContext} from 'contexts/LocationContext';

import Header from 'components/Rewards/Header/Header';
import DealsPage from './DealsPage/DealsPage';
import VouchersPage from './VouchersPage/VouchersPage';
import LotsPage from './LotsPage/LotsPage';
import ClimatePartnerPage from './ClimatePartnerPage/ClimatePartnerPage';
import NearbyOffersPage from './NearbyOffersPage/NearbyOffersPage';
import {ActiveOffersFilter} from "types/Offers";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: calc(100vh - 95px);
`;

const Content = styled.div`
  background: ${(props) =>
    props.theme.components.offers.bodyBgColor || props.theme.colors.secondary};
  flex-grow: 1;
  overflow-y: scroll;
`;

const RewardsPage = () => {
  const {pathname} = useLocation();
  const {userProfile, reloadUserProfile} = useUserProfile();
  const {lastVoucherUpdate} = useContext(SettingsContext);
  const {updateCurrentLocation} = useContext(LocationContext);
  const {hasClimatePartners} = useOrganization();

  const [activeOffersFilter, setActiveOffersFilter] = useState<ActiveOffersFilter>({
    businessTypes: [],
  });

  const hearts = userProfile?.user.recoins || 0;

  useEffect(
    () => {
      reloadUserProfile();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastVoucherUpdate],
  );

  useEffect(() => {
    if (hasClimatePartners) {
      updateCurrentLocation();
    }
  }, [hasClimatePartners, updateCurrentLocation]);

  return (
    <Wrapper>
      <Header
        hearts={hearts}
        showOffersFilter={pathname === routes.REWARDS.DEALS.href}
        activeOffersFilter={activeOffersFilter}
        onFilter={setActiveOffersFilter}
      />
      <Content>
        <Switch>
          <Route path={routes.REWARDS.VOUCHERS.href}>
            <VouchersPage />
          </Route>
          <Route path={routes.REWARDS.LOTS.href}>
            <LotsPage />
          </Route>
          <Route path={routes.REWARDS.DEALS.href}>
            <DealsPage activeOffersFilter={activeOffersFilter} />
          </Route>
          <Route path={routes.REWARDS.NEARBY_OFFERS.href}>
            <NearbyOffersPage />
          </Route>
          <Route path={`${routes.REWARDS.CLIMATE_PARTNER.href}/:id`}>
            <ClimatePartnerPage />
          </Route>
          <Route path="/">
            <Redirect to={routes.REWARDS.DEALS.href} />
          </Route>
        </Switch>
      </Content>
    </Wrapper>
  );
};

export default RewardsPage;
