import React from 'react';
import styled from 'styled-components';

import {addGlobalBoxShadow, addGlobalSquareCorners} from 'utils/theme';
import CopyText from 'components/Text/CopyText/CopyText';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import Recoins from './Recoins/Recoins';
import WishList from '../WishList/WishList';
import {UpdateVoucherWishlist} from '../WishList/use-wishlist';
import {OfferType} from 'types/Offers';

const Wrapper = styled.div<{isTransparent?: boolean}>`
  background-color: ${(props) =>
    props.isTransparent ? 'transparent' : props.theme.colors.white};
  cursor: pointer;
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) =>
    props.theme.components.offers?.hasSquareCorners || '7px'};

  ${(props) => {
    if (props.isTransparent) {
      return `
        margin-top: 12px;
      `;
    }
    return '';
  }}

  ${addGlobalSquareCorners()}
  ${addGlobalBoxShadow()}
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
  min-height: 50px;
`;

const Image = styled.img<{height?: string}>`
  width: 100%;
  display: block;
  height: ${(props) => props.height};
  object-fit: cover;
`;

const Actions = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`;

const Content = styled(CopyText)`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;
  z-index: 100;
`;

const Text = styled(CopyTextSm)`
  margin-bottom: 10px;
  display: block;
`;

const TextLg = styled(CopyText)`
  margin-bottom: 10px;
  display: block;
`;

interface CardProps {
  id: number;
  lg?: boolean;
  isTransparent?: boolean;
  wishlisted: boolean;
  title: string;
  recoins: number;
  cost: number;
  image: string;
  image_file?: {
    url: string;
  };
  offer_type_id: OfferType;
  userRecoins: number;
  remaining?: number;
  imageHeight?: string;
  children?: React.ReactNode;
  updateVoucherWishlist: UpdateVoucherWishlist;
}

const Card = (props: CardProps) => {
  const {
    id,
    title,
    recoins,
    cost,
    image,
    image_file,
    offer_type_id,
    remaining,
    lg,
    wishlisted,
    isTransparent,
    imageHeight,
    children,
    updateVoucherWishlist,
  } = props;

  return (
    <Wrapper isTransparent={isTransparent}>
      <ImageWrapper>
        <Image src={image_file?.url || image} alt="" height={imageHeight} />
        <Actions>
          <Recoins recoins={cost || recoins} offersCount={remaining} />
          {offer_type_id === OfferType.OFFLINE && (
            <WishList
              wishlisted={wishlisted}
              id={id}
              updateVoucherWishlist={updateVoucherWishlist}
            />
          )}
        </Actions>
      </ImageWrapper>
      <Content>
        {lg ? <TextLg>{title}</TextLg> : <Text>{title}</Text>}
        {children && <div>{children}</div>}
      </Content>
    </Wrapper>
  );
};

export default Card;
