import React, {useMemo} from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import {useOrganization} from 'hooks/use-organization';
import {getOptimizedDistance, OptimizedDistance} from 'utils/numbers';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import Tag from 'components/Rewards/Tags/Tag';

import {Voucher, OfferType as VoucherTypeEnum} from 'types/Offers';
import {ReactComponent as LocationIcon} from 'icons/location.svg';
import {ReactComponent as GlobeIcon} from 'icons/globe.svg';

const Wrapper = styled(Tag)`
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${(props) =>
    props.theme.components.offers.locationTypeBgColor || 'transparent'};
`;

const Icon = styled.div<{globe?: boolean}>`
  display: flex;
  align-items: center;
  fill: ${(props) =>
    props.globe
      ? 'transparent'
      : props.theme.components.offers.locationTypeColor ||
        props.theme.colors.primary};
  stroke: ${(props) =>
    props.globe
      ? props.theme.components.offers.locationTypeColor ||
        props.theme.colors.primary
      : 'transparent'};
  margin-right: 5px;

  svg {
    width: ${(props) => (props.globe ? '16px' : '11px')};
  }
`;

const Text = styled(CopyTextSm)`
  display: block;
  color: ${(props) =>
    props.theme.components.offers.locationTypeColor ||
    props.theme.colors.primary};
`;

type VoucherTypeProps = {
  voucher: Voucher;
  optimizeDistance?: boolean;
};

const LocationType = (props: VoucherTypeProps) => {
  const {voucher, optimizeDistance} = props;
  const {translate} = useTranslations();
  const {hasClimatePartners} = useOrganization();

  const {showOffline, showDistance, distance} =
    useMemo<OptimizedDistance>(() => {
      if (voucher.offer_type_id === VoucherTypeEnum.ONLINE) {
        return {};
      }

      return getOptimizedDistance({
        distance: voucher.climate_partner?.distance,
        optimizeDistance,
      });
    }, [voucher, optimizeDistance]);

  if (!hasClimatePartners) {
    return null;
  }

  if (voucher.offer_type_id === VoucherTypeEnum.OFFLINE && !showOffline) {
    return null;
  }

  return (
    <Wrapper>
      {voucher.offer_type_id === VoucherTypeEnum.ONLINE ? (
        <>
          <Icon globe={true}>
            <GlobeIcon />
          </Icon>
          <Text>{translate('sdk.web.offers.online')}</Text>
        </>
      ) : (
        <>
          <Icon>
            <LocationIcon />
          </Icon>
          <Text> {showDistance ? `${distance} km` : 'n/a '}</Text>
        </>
      )}
    </Wrapper>
  );
};

export default LocationType;
