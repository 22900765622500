import React, {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import {CACHE_KEYS} from 'constants/cache-keys';
import {useFetcher} from 'hooks/use-fetcher';
import {useTranslations} from 'hooks/use-translations';
import {getBusinessTypes} from 'services/vouchers';
import {ActiveOffersFilter, OffersBusinessType, OfferType} from 'types/Offers';

import RadioButton from 'components/Form/RadioButton';
import Loader from 'components/Loader/Loader';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import PromptWrapper from 'components/PromptModal/PromptWrapper/PromptWrapper';
import Icon from 'components/Rewards/Header/Icon';

import {ReactComponent as FilterIcon} from 'components/Rewards/Header/icons/filter.svg';

const Wrapper = styled.div`
  margin-right: 10px;

  .offers-filter {
    &__filter {
      position: relative;
    }

    &__indicator {
      position: absolute;
      right: -2px;
      top: -1px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #b71e3f;
      border: 1px solid #ffffff;
    }

    &__list {
      height: 100%;
      overflow-y: scroll;
    }
  }
`;

const ListWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 0 20px 20px;

  .offers-filter-list {
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &__text {
      margin-left: 15px;
    }
  }
`;

const Separator = styled.div`
  height: 1.5px;
  background-color: #707070;
  margin-top: -22.5px;
  margin-bottom: 20px;
  width: 50%;
  margin-left: 20px;
`;

type ListData = {
  id: number;
  slug: string;
  name: string;
  checked: boolean;
  value: any;
};

interface OfferListProps {
  isLoading?: boolean;
  list: Array<ListData>;
  onFilter: (value: any) => any;
}

const FilterList = (props: OfferListProps) => {
  const {isLoading, list, onFilter} = props;

  return (
    <ListWrapper className="offers-filter-list">
      {isLoading ? (
        <Loader color="#222" sm />
      ) : (
        list.map((item) => (
          <div
            key={item.id}
            className="offers-filter-list__item"
            onClick={() => onFilter(item.value)}>
            <RadioButton checked={item.checked} />
            <CopyTextSm as="div" className="offers-filter-list__text">
              {item.name}
            </CopyTextSm>
          </div>
        ))
      )}
    </ListWrapper>
  );
};

interface OffersFilterProps {
  activeOffersFilter: ActiveOffersFilter;
  onFilter: (filters: ActiveOffersFilter) => any;
}

const OffersFilter = (props: OffersFilterProps) => {
  const {activeOffersFilter, onFilter} = props;
  const {translate} = useTranslations();

  const [showFilters, setShowFilters] = useState(false);

  const {isLoading, data} = useFetcher<Array<OffersBusinessType>>({
    fetcher: getBusinessTypes,
    key: CACHE_KEYS.OFFERS_BUSINESS_TYPES,
    initialValue: [],
  });

  const offerTypeList = useMemo(() => {
    return [
      {
        id: 0,
        slug: 'all',
        name: translate('sdk.web.offers.filter.all'),
        checked: !activeOffersFilter.offerTypeId,
        value: undefined,
      },
      {
        id: OfferType.ONLINE,
        slug: 'online',
        name: translate('sdk.web.offers.filter.online'),
        checked: activeOffersFilter.offerTypeId === OfferType.ONLINE,
        value: OfferType.ONLINE,
      },
      {
        id: OfferType.OFFLINE,
        slug: 'onsite',
        name: translate('sdk.web.offers.filter.onsite'),
        checked: activeOffersFilter.offerTypeId === OfferType.OFFLINE,
        value: OfferType.OFFLINE,
      },
    ];
  }, [translate, activeOffersFilter]);

  const businessTypesList = useMemo(() => {
    const allBusinessTypes = data.map((businessType) => ({
      ...businessType,
      checked: activeOffersFilter.businessTypes.includes(
        businessType.id.toString(),
      ),
      value: businessType.id,
    }));

    return [
      {
        id: 0,
        slug: 'all',
        name: translate('sdk.web.offers.filter.all'),
        checked: activeOffersFilter.businessTypes.length === 0,
        value: 0,
      },
      ...allBusinessTypes,
    ];
  }, [translate, activeOffersFilter, data]);

  const handleOfferTypeFilter = useCallback(
    (offerTypeId?: OfferType) => {
      onFilter({
        ...activeOffersFilter,
        offerTypeId,
      });
    },
    [onFilter, activeOffersFilter],
  );

  const handleBusinessTypeFilter = useCallback(
    (id: number) => {
      if (id === 0) {
        onFilter({
          ...activeOffersFilter,
          businessTypes: [],
        });
        return;
      }

      const idStr = id.toString();
      const businessTypes = activeOffersFilter.businessTypes.includes(idStr)
        ? activeOffersFilter.businessTypes.filter((filter) => filter !== idStr)
        : [...activeOffersFilter.businessTypes, idStr];
      onFilter({
        ...activeOffersFilter,
        businessTypes,
      });
    },
    [onFilter, activeOffersFilter],
  );

  return (
    <Wrapper className="offers-filter">
      <div
        className="offers-filter__filter"
        onClick={() => setShowFilters(true)}>
        <Icon Icon={FilterIcon} />
        {(activeOffersFilter.businessTypes.length > 0 ||
          activeOffersFilter.offerTypeId) && (
          <div className="offers-filter__indicator" />
        )}
      </div>
      <PromptWrapper
        isVisible={showFilters}
        onClose={() => setShowFilters(false)}
        title={translate('sdk.web.offers.filter.title')}
        styles={{pb: '0'}}>
        <div>
          <FilterList list={offerTypeList} onFilter={handleOfferTypeFilter} />
        </div>
        <Separator />
        <FilterList
          isLoading={isLoading}
          list={businessTypesList}
          onFilter={handleBusinessTypeFilter}
        />
      </PromptWrapper>
    </Wrapper>
  );
};

export default OffersFilter;
