import React, {PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';

import {OptimizedDistance} from 'utils/numbers';

import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import TextWithLinks from 'components/Text/TextWithLinks';

import {ReactComponent as LocationIcon} from 'icons/location.svg';
import {ReactComponent as TimesIcon} from 'icons/times.svg';

const motionVariants = {
  hidden: {
    y: '100vh',
    transition: {duration: 0.3, ease: 'easeInOut'},
  },
  visible: {
    y: 0,
    transition: {ease: 'easeInOut'},
  },
};

const List = styled(motion.div)<{bgColor?: string}>`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3000;
  width: 100%;
  background-color: ${(props) =>
    props.bgColor || props.theme.components.climatePartnerMap?.bgColor};
  padding: 10px;
  padding-bottom: 15px;
  border-radius: 10px 10px 0 0;
`;

const CloseIcon = styled.div`
  top: 10px;
  right: 10px;
  position: absolute;
  z-index: 10;
  width: 20px;
  height: 20px;
`;

const Content = styled.div`
  margin-bottom: 10px;
  display: flex;
  padding-right: 15px;
`;

const Image = styled.img`
  width: 129px;
  height: 96.75px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
  flex-shrink: 0;
`;

const Info = styled(CopyTextSm)`
  display: block;
  margin-bottom: 10px;
  max-height: 60vh;
  overflow-y: auto;
`;

const Address = styled.span`
  color: ${(props) =>
    props.theme.components.offers.locationTypeColor ||
    props.theme.colors.primary};
  text-decoration: underline;
`;

const Location = styled(CopyTextSm)`
  display: flex;
  max-width: fit-content;
  padding: 2px 4px;
  background-color: #ffffff;
  border-radius: 3px;
  color: ${(props) =>
    props.theme.components.offers.locationTypeColor ||
    props.theme.colors.primary};
`;

const LocationIconWrapper = styled.div<{mr?: boolean}>`
  display: flex;
  align-items: center;
  fill: ${(props) =>
    props.theme.components.offers.locationTypeColor ||
    props.theme.colors.primary};
  stroke: transparent;
  margin-right: ${(props) => (props.mr ? '5px' : '0')};

  svg {
    width: 11px;
  }
`;

interface MapInfoProps extends PropsWithChildren {
  name: string | ReactElement;
  image?: string;
  description?: string;
  address: string;
  location?: OptimizedDistance;
  onDirectionRequest: () => any;
  onClose: () => any;
  styles?: {
    bgColor?: string;
  };
}

const MapInfo = (props: MapInfoProps) => {
  const {
    name,
    image,
    description,
    address,
    location,
    styles,
    children,
    onDirectionRequest,
    onClose,
  } = props;

  return (
    <List
      variants={motionVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      bgColor={styles?.bgColor}>
      <Content>
        {image && <Image src={image} alt="" />}
        <div>
          <Info>
            {name}
            <br />
            <Address onClick={onDirectionRequest}>{address}</Address>
          </Info>
          {description && (
            <Info>
              <TextWithLinks text={description} />
            </Info>
          )}
          {location && (
            <>
              {location.showOffline && (
                <Location>
                  <LocationIconWrapper mr={location.showDistance}>
                    <LocationIcon />
                  </LocationIconWrapper>
                  {location.showDistance && <span>{location.distance} km</span>}
                </Location>
              )}
            </>
          )}
        </div>
      </Content>
      <CloseIcon onClick={onClose}>
        <TimesIcon />
      </CloseIcon>
      {children && children}
    </List>
  );
};

export default MapInfo;
