import {useContext, useMemo} from 'react';
import pick from 'lodash/pick';

import {SettingsContext} from 'contexts/SettingsContext';
import {APP_FEATURES} from 'constants/app-features';
import {OfferType} from 'types/Offers';

export const useOrganization = () => {
  const {organizationConfig, appFeatures} = useContext(SettingsContext);
  const isChangersSdk = organizationConfig.name === 'sdksample';

  const {hasClimatePartners, hasHomeQrScanner, hasUserAccount, hasBadgeShare} =
    useMemo(() => {
      const hasClimatePartners =
        appFeatures.includes(APP_FEATURES.CLIMATE_PARTNERS) &&
        organizationConfig.features.hasClimatePartners;

      const hasHomeQrScanner =
        hasClimatePartners &&
        organizationConfig.features.hasHomeQrScanner &&
        appFeatures.includes(APP_FEATURES.HOME_QR_CODE_SCANNER);

      const hasUserAccount =
        organizationConfig.features.hasUserAccount &&
        appFeatures.includes(APP_FEATURES.USER_ACCOUNT);

      const hasBadgeShare = appFeatures.includes(APP_FEATURES.BADGE_SHARE);

      return {
        hasClimatePartners,
        hasHomeQrScanner,
        hasUserAccount,
        hasBadgeShare,
      };
    }, [organizationConfig, appFeatures]);

  const voucherTypes = useMemo(
    () =>
      [OfferType.ONLINE, hasClimatePartners && OfferType.OFFLINE]
        .filter((type) => type)
        .join(','),
    [hasClimatePartners],
  );

  return {
    organizationConfig,
    isChangersSdk,
    hasHomeQrScanner,
    hasClimatePartners,
    hasUserAccount,
    hasBadgeShare,
    voucherTypes,
    ...pick(organizationConfig.features, [
      'hasUserRanking',
      'hideUserRankingParticipants',
      'hasUserRankingMobility',
      'hideUserRankingInCities',
      'hasMetering',
      'hasWaterWidget',
      'hasCo2Compensation',
      'hasCustomActivities',
      'hasBadges',
      'hasCompetition',
      'hasNewFAQ',
      'hasCo2Emissions',
      'hasLotteryForm',
      'hasNotifications',
      'hasWidgetCustomization',
      'hasWallets',
      'hasNewQRScanner',
      'hasVehicleSettings',
      'hasClimateTreasure',
      'hasBambooCoin',
      'hasSocioDemoData',
      'hasSintraBannerWidget',
      'hasAachenSurvey',
      'hasCo2SavingsInfoText',
      'hasCustomActivityInfoText',
    ]),
  };
};
