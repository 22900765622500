import React from 'react';
import styled from 'styled-components';

import {useTranslations} from 'hooks/use-translations';
import useClimatePartnerLocation from 'hooks/use-climate-partner-location';

import {Voucher} from 'types/Offers';
import CopyTextSm from 'components/Text/CopyTextSm/CopyTextSm';
import OfferScan from './OfferScan/OfferScan';

import {ReactComponent as LocationIcon} from 'icons/location.svg';
import {ReactComponent as RightCaretIcon} from 'icons/right-caret.svg';

const Location = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: ${(props) =>
    props.theme.components.offers.directionColor || props.theme.colors.primary};
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  fill: ${(props) =>
    props.theme.components.offers.directionColor || props.theme.colors.primary};
  width: 10.5px;

  svg {
    width: 100%;
  }
`;

const RightCaret = styled(Icon)`
  width: 21px;
`;

const Name = styled(CopyTextSm)`
  display: block;
  margin-bottom: 5px;
`;

const Address = styled(CopyTextSm)`
  display: block;
  margin: 0 8px;
`;

const Info = styled(CopyTextSm)`
  margin-bottom: 20px;
  display: block;
`;

type OfferDirectionProps = {
  voucher: Voucher;
  isScanned: boolean;
  onScan: (...params: any) => any;
};

const OnsiteCTA = (props: OfferDirectionProps) => {
  const {voucher, isScanned, onScan} = props;
  const {translate} = useTranslations();
  const {address, handleDirectionRequest} = useClimatePartnerLocation({
    climatePartner: voucher.climate_partner,
  });

  return (
    <div>
      {voucher.climate_partner && (
        <Name as="div" className="mb-base">
          <b>{voucher.climate_partner.name}</b>
        </Name>
      )}
      <Location onClick={handleDirectionRequest}>
        <Icon>
          <LocationIcon />
        </Icon>
        <Address>{address}</Address>
        <RightCaret>
          <RightCaretIcon />
        </RightCaret>
      </Location>
      <Info>{translate('sdk.web.offers.onsite.info')}</Info>
      {!isScanned && <OfferScan voucher={voucher} onScan={onScan} />}
    </div>
  );
};
export default OnsiteCTA;
