import Co2Thaler from 'components/ClimateWidgets/Co2Token/AachanCoin';
import fullTokenImg from 'components/ClimateWidgets/Co2Token/AachanCoin/images/coins/full.svg';
import {ReactComponent as Co2TokenInfoIcon} from 'components/ClimateWidgets/Co2Token/images/aachen/info.svg';

import onboardingOneImg from 'components/Onboarding/images/aachen/onboarding-1.svg';
import onboardingTwoImg from 'components/Onboarding/images/aachen/onboarding-2.svg';
import onboardingThreeImg from 'components/Onboarding/images/aachen/onboarding-3.svg';
import onboardingGpsImg from 'components/Onboarding/images/aachen/onboarding-gps.svg';
import onboardingMotionImg from 'components/Onboarding/images/aachen/onboarding-motion.svg';
import onboardingTeamsImg from 'components/Onboarding/images/aachen/onboarding-teams.svg';
import onboardingPrivacyImg from 'components/Onboarding/images/aachen/onboarding-privacy.svg';
import onboardingNotificationsImg from 'components/Onboarding/images/aachen/onboarding-notifications.svg';

import customActivitiesImgEn from 'components/ClimateWidgets/Mobility/images/aachen/custom-activities-en.svg';
import customActivitiesImgDe from 'components/ClimateWidgets/Mobility/images/aachen/custom-activities-de.svg';
import cyclingImg from 'components/ClimateWidgets/Mobility/images/aachen/cycling.svg';
import publicTransitImg from 'components/ClimateWidgets/Mobility/images/aachen/public-transit.svg';
import walkingImg from 'components/ClimateWidgets/Mobility/images/aachen/walking.svg';

import treePlantingImage from 'components/ClimateWidgets/TreePlanting/images/aachen/tree.svg';
import treePlantingGoalImage from 'components/ClimateWidgets/TreePlanting/TreeGoalReachedPrompt/images/aachen/banner.svg';
import treePlantingNewTreeImage from 'components/ClimateWidgets/TreePlanting/NewTreePrompt/images/aachen/banner.svg';
import treePlantingHalfwayImage from 'components/ClimateWidgets/TreePlanting/HalfwayTreePrompt/images/aachen/banner.svg';

import {ReactComponent as InviteFriendsInfoIcon} from 'components/ClimateWidgets/InviteFriends/icons/aachen/info.svg';
import {ReactComponent as LocationLinkIcon} from 'icons/aachen/location-link.svg';

import teamBannerImg from 'components/ClimateWidgets/Leaderboard/images/aachen/banner.svg';
import teamGroupBannerImg from 'components/ClimateWidgets/Leaderboard/images/aachen/group-banner.svg';
import teamGoldImg from 'components/ClimateWidgets/Leaderboard/images/aachen/gold.svg';
import teamSilverImg from 'components/ClimateWidgets/Leaderboard/images/aachen/silver.svg';
import teamBronzeImg from 'components/ClimateWidgets/Leaderboard/images/aachen/bronze.svg';

import {ReactComponent as AllMobilityIcon} from 'components/ClimateWidgets/Leaderboard/images/aachen/all-mobility.svg';
import {ReactComponent as WalkingMobilityIcon} from 'components/ClimateWidgets/Leaderboard/images/aachen/walking.svg';
import {ReactComponent as CyclingMobilityIcon} from 'components/ClimateWidgets/Leaderboard/images/aachen/cycling.svg';
import {ReactComponent as PublicTransitMobilityIcon} from 'components/ClimateWidgets/Leaderboard/images/aachen/public-transit.svg';

import notificationsRecoinIcon from 'icons/push-notifications/aachen/recoins.svg';
import notificationsChallengeIcon from 'icons/push-notifications/aachen/challenges.svg';

// import climateTreasureIcon from 'components/ClimateWidgets/ClimateTreasure/images/aachen/icon.svg';

import buttons from 'config/organizations/preset/buttons';

const config = {
  name: 'aachen',
  theme: {
    fonts: {
      en: {
        android: {
          primary: 'Transit Pro',
          secondary: 'TheMixOsF Black',
        },
        ios: {
          primary: 'Transit Pro',
          secondary: 'TheMixOsF Black',
        },
      },
      de: {
        android: {
          primary: 'Transit Pro',
          secondary: 'TheMixOsF Black',
        },
        ios: {
          primary: 'Transit Pro',
          secondary: 'TheMixOsF Black',
        },
      },
      ja: {
        android: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
        ios: {
          primary: 'Noto Sans',
          secondary: 'Noto Sans',
        },
      },
    },
    colorPalette: {
      primary: '#E5C500',
      'primary-500': '#047D8C',
      'primary-300': '#ECD35E',
      secondary: '#DDDDDD',
      light: '#FFFFFF',
      white: '#FFFFFF',
      dark: '#363636',
      black: '#363637',
      gray: '#D8D9DA',
      lightGray: '#F1F1F1',
      glacierLight: '#80BCC3FA',
      sunDown: '#FFAFAF',
    },
    components: {
      global: {
        hasSquareCorners: true,
        borderColor: 'primary',
      },
      dashboard: {
        bgColor: 'secondary',
      },
      competition: {
        bgColor: 'secondary',
      },
      nav: {
        bgColor: 'primary',
        menuColor: 'white',
        inactiveOpacity: 0.7,
        routes: ['impact', 'offers', 'teams', 'notifications', 'more'],
      },
      widgetCard: {
        bgColor: 'light',
        titleBgColor: 'dark',
        titleColor: 'white',
        textColor: 'black',
      },
      offers: {
        navBgColor: 'primary-300',
        bodyBgColor: 'secondary',
        activeTabColor: 'black',
        activeTabBgColor: 'primary',
        recoinsLeftBgColor: 'secondary',
        recoinsLeftColor: 'black',
        recoinsRequiredBgColor: 'secondary',
        navColor: 'black',
        locationTypeBgColor: 'secondary',
        locationTypeColor: 'black',
        directionColor: 'black',
        headerIconsColor: 'black',
        LocationLinkIcon,
      },
      badges: {
        bgColor: 'light',
        titleColor: 'primary',
      },
      treePlanting: {
        bgColor: 'white',
        treeImage: treePlantingImage,
        goalBanner: treePlantingGoalImage,
        newTreeBanner: treePlantingNewTreeImage,
        halfwayBanner: treePlantingHalfwayImage,
        progressPathColor: '#E5C500',
        timerPathColor: '#363636',
        progressBgColor: '#F1F1F1',
      },
      buttons: {
        ...buttons,
        fontFamily: 'secondary',
        fontWeight: 'bold',
      },
      links: {
        textColor: 'black',
      },
      mobilityIcons: {
        progressPathColor: 'primary',
        walking: {
          bgColor: 'transparent',
          img: walkingImg,
        },
        cycling: {
          bgColor: 'transparent',
          img: cyclingImg,
        },
        publicTransit: {
          bgColor: 'transparent',
          img: publicTransitImg,
        },
        customActivities: {
          bgColor: 'transparent',
          en: {
            img: customActivitiesImgEn,
          },
          de: {
            img: customActivitiesImgDe,
          },
        },
      },
      prompts: {
        bgColor: 'light',
        overlayBgColor: '#00000099',
        closeIconColor: 'dark',
        titleFontFamily: 'secondary',
      },
      customActivities: {
        titleColor: 'primary',
        detailsBgColor: 'secondary',
        listBgColor: 'white',
        successPromptColor: 'white',
        caretColor: 'black',
        activeCheckColor: 'black',
        ctaIconCheckColor: 'black',
      },
      settings: {
        bgColor: 'light',
        itemBorderColor: 'glacierLight',
        itemCaretColor: 'primary',
      },
      co2Compensation: {
        pageBgColor: 'secondary',
      },
      leaderboard: {
        bgColor: 'white',
        rankingBorderColor: 'primary',
        ctaColor: 'black',
        banner: teamBannerImg,
        groupBanner: teamGroupBannerImg,
        goldImg: teamGoldImg,
        silverImg: teamSilverImg,
        bronzeImg: teamBronzeImg,
        mobilityIcons: {
          Walking: WalkingMobilityIcon,
          Cycling: CyclingMobilityIcon,
          PublicTransit: PublicTransitMobilityIcon,
          All: AllMobilityIcon,
        },
      },
      inviteFriends: {
        titleColor: 'primary',
        modalBgColor: 'light',
        progressBarBgColor: '#CACACA',
        progressBarColor: 'dark',
        shareIconColor: 'dark',
        InfoIcon: InviteFriendsInfoIcon,
      },
      vouchers: {
        bgColor: 'white',
        active: {
          activeBgColor: 'primary-300',
          pendingBgColor: 'secondary',
          expiredBgColor: 'sunDown',
          progressColor: 'black',
          progressTrailColor: 'secondary',
          headerFontFamily: 'secondary',
        },
        online: {
          titleFontFamily: 'secondary',
        },
      },
      voucherCard: {
        progressColor: 'black',
      },
      climatePartnerMap: {
        bgColor: 'secondary',
      },
      news: {
        bgColor: 'secondary',
        titleBgColor: 'white',
        titleColor: 'black',
        style: 'comment',
      },
      teams: {
        banner: onboardingTeamsImg,
      },
      co2Token: {
        InfoIcon: Co2TokenInfoIcon,
      },
      onboarding: {
        titleFontFamily: 'secondary',
        images: {
          one: {
            image: onboardingOneImg,
          },
          two: {
            image: onboardingTwoImg,
          },
          three: {
            image: onboardingThreeImg,
          },
          privacy: {
            image: onboardingPrivacyImg,
          },
          gps: {
            image: onboardingGpsImg,
          },
          motion: {
            image: onboardingMotionImg,
          },
          teams: {
            image: onboardingTeamsImg,
          },
          notifications: {
            image: onboardingNotificationsImg,
          },
        },
      },
      metering: {
        widget: {
          bgColor: 'lightGray',
          caretColor: 'black',
        },
        infoIconColor: 'black',
        updateReadingBgColor: 'primary-300',
        updateReadingCaretColor: 'black',
        prevResultsMonthBgColor: 'lightGray',
      },
      form: {
        radioColor: 'black',
      },
      notifications: {
        tileBgColor: 'white',
        caretColor: 'black',
        unreadCountBg: 'black',
        unreadCountColor: 'white',
        icons: {
          recoin: notificationsRecoinIcon,
          challenge: notificationsChallengeIcon,
        },
      },
      wallets: {
        textColor: 'black',
      },
      socioDemoData: {},
      climateTreasure: {
        // icon: climateTreasureIcon
      },
    },
    colors: {
      primary: '#E5C500',
      secondary: '#DDDDDD',
      white: '#ffffff',
      black: '#3c3c3c',
      gray: '#D8D9DA',
      promptOverlayBg: '#00000099',
      onboardingBg: '#FFFFFF',
      teamRankingHighlight: '#FFF3D7',
    },
  },
  translations: {
    cityName: 'sdk.web.aachen.city.name',
    shortTokenSingular: 'sdk.web.aachen.token.short.singular',
    shortTokenPlural: 'sdk.web.aachen.token.short.plural',
    faq: {
      categories: [
        {
          key: 'general',
          name: 'aachen.faq_general_city_name',
          questions: [
            {
              key: 'faq_general_city_question_1',
              answer: 'aachen.faq_general_city_answer_1',
            },
            {
              key: 'faq_general_city_question_3',
              answer: 'aachen.faq_general_city_answer_3',
            },
            {
              key: 'faq_general_city_question_6',
              answer: 'aachen.faq_general_city_answer_6',
            },
          ],
        },
      ],
    },
    leaderboard: {
      leaderboards: [
        {
          index: 0,
          title: 'sdk.web.aachen.teams.title',
          subtitle: 'sdk.web.aachen.teams.subtitle',
        },
      ],
    },
    climateWidgets: {
      activities: {
        title: 'sdk.web.aachen.ca.widget.title',
      },
    },
  },
  features: {
    hideTerms: true,
    hideAppClose: true,
    hideLotsTab: false,
    hasCompetition: true,
    hasClimatePartners: true,
    hasHomeQrScanner: true,
    hasUserAccount: true,
    hasCustomActivities: true,
    hasCo2Compensation: false,
    hasBadges: true,
    hasUserRanking: true,
    hasUserRankingMobility: true,
    hasNewFAQ: true,
    hasCo2Emissions: true,
    hasMetering: true,
    hasNotifications: true,
    hasWidgetCustomization: true,
    hasWallets: true,
    hasNewQRScanner: true,
    hasVehicleSettings: true,
    hasSocioDemoData: true,
    hasClimateTreasure: true,
    hasCo2SavingsInfoText: true,
    hasAachenSurvey: true,
    hasCustomActivityInfoText: true,
  },
  featuresConfig: {
    socioDemoData: {
      whitelistedFields: ['district'],
    },
  },
  applangaTag: '628b91e87ee1d43c9471c63a',
  matomoTrackingId: 3,
  tokenType: 'Thaler',
  fullTokenImg,
  FullTokenComponent: Co2Thaler,
};

export default config;
