import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';

import routes from 'config/routes';
import {useTranslations} from 'hooks/use-translations';
import {SettingsContext} from 'contexts/SettingsContext';

import WidgetTile from 'components/ClimateWidgets/Widget/WidgetTile';

import icon from './images/icon.svg';
import banner from './images/banner.svg';

const ClimateTreasure = () => {
  const {organizationConfig} = useContext(SettingsContext);
  const history = useHistory();
  const {translate} = useTranslations();

  const title = translate(
    organizationConfig.translations.climateTreasure?.title ||
      'sdk.web.treasure.map.title',
  );
  const image =
    organizationConfig.theme.components.climateTreasure?.icon || icon;

  return (
    <WidgetTile
      title={title}
      subtitle={translate('sdk.web.treasure.widget.subtitle')}
      image={image}
      banner={banner}
      onClick={() => history.push(routes.CLIMATE_TREASURE.href)}
    />
  );
};

export default ClimateTreasure;
